
	//  -------------------------------------------------
	//  MAP - Using provided address, or coords 
 
	
	// Parameters
	var position_collection = new Array;
	var primary_position;
	var current_infoWindow;

	// Locate map center point using provided address

	function set_center_map(map, fitbound){
		// If position_collection have more than one position and set to fitbound

		if (position_collection.length > 0 && fitbound)
		{
			var bound = new google.maps.LatLngBounds()
			// Loop on all position

		 	for (var i = 0; i < position_collection.length; i++) 
		 	{
		   		bound.extend(position_collection[i]);
			}
		  	
		  	map.fitBounds(bound);
		}
		else
		{	
			map.setCenter(primary_position);			
		}

	}

	function locate_using_coords(positionObject, map, markerBool, fitbound, multiple_markersBool){ 
	 	// If lat lng are provided
	 	lat_lng = { lat: positionObject.dataLat, lng: positionObject.dataLng};	
	 	// Recenter map using coords
	 	// set_center_map(map,lat_lng);
	 	// Place a marker using coords
	 	if (markerBool == 1 && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {
		    var marker = new google.maps.Marker({
		        map: map,
		        position: lat_lng,
		        icon: positionObject.img,
		        content: positionObject.html
		    });

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {	      	  	
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });
				  
				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		}

		// Set the collection of marker or the primary position

		if (positionObject.primary)
		{
			primary_position = lat_lng;
		}
		else
		{
	    	position_collection.push(lat_lng);
		}

	    set_center_map(map,fitbound);
	} // locate_using_coords()


	function locate_using_address(positionObject,resultsMap,currentMapWrap, markerBool, fitbound, multiple_markersBool) {

		var geocoder = new google.maps.Geocoder();
	  	geocoder.geocode({'address': positionObject.address}, function(results, status) {
	    
	    // If geocoding successfull
	    if (status === google.maps.GeocoderStatus.OK) {
	      // lat_lng = results[0].geometry.location;

	      // Place a marker using address
	      if ((markerBool == 1) && ((!multiple_markersBool && positionObject.primary) || (multiple_markersBool && !positionObject.primary))) {

	      	 var marker = new google.maps.Marker({
		        map: resultsMap,
		        position: results[0].geometry.location,
		        icon: positionObject.img,
		      });      	

	      	  // If html is not empty fo a info window

	      	  if (positionObject.html != "")
	      	  {	      	  	
				  var infowindow = new google.maps.InfoWindow({
					    content: positionObject.html
				  });
				  
				  marker.addListener('click', function() {
				    if (current_infoWindow) {
				    	current_infoWindow.close();
				    }
				    current_infoWindow = infowindow;
				    infowindow.open(resultsMap, marker);
				  });
	      	  }
		   }

		   	 // Set the collection of marker or the primary position

	     	 if (positionObject.primary)
	     	 {
				primary_position = results[0].geometry.location;
	     	 }
	     	 else
	     	 {
	      	 	position_collection.push(results[0].geometry.location);
	     	 }

	      	 set_center_map(resultsMap,fitbound);
	    } // geocoding successful

	    // If geocoding unsuccessful from provided address 
	    // (ex: no address provided), Use provided lat-lng instead.
	    else { 
	      	console.log(currentMapWrap.attr("class")+" -> Geocoding unsuccessful ("+positionObject.address+"); " + status); 
	      	//currentMapWrap.hide();
	   		if(positionObject.dataLat != "" && positionObject.dataLng != "" && positionObject.dataLat != undefined && positionObject.dataLng != undefined){
		 		locate_using_coords(positionObject,resultsMap,markerBool,fitbound); 
		 	} 
		 	else{
		 		currentMapWrap.hide();
		 	}
	    } // If geocoding unsuccessful

	  }); 

	} // locate_using_address

	function initMap(){
 

		// Witness pointer
		var index = 0; 

		var colors = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e7e8e0"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "all",
        "stylers": [
            {
                "color": "#e7e8e0"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e7e8e0"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#e7e8e0"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    }
];
	

		// If at least one map on page

		if ($(".Map").length) { 



			// Browse each map info and create a map
		 	$(".Map").each(function(){


		 		// Parameters

				position_collection = new Array;
				primary_position;

		 		var zoom = $(this).data("zoom"); 
		 		if (zoom == undefined || zoom == "") { zoom = 10; };

		 		var colored = $(this).data("colored"); 
		 		if (colored == undefined || colored == "" || colored == 0) { colored = 0; } 
		 		else{ colored = colors;};

		 		var marker = $(this).data("marker"); 
		 		if (marker == undefined || marker == "") { marker = 0; };

		 		var ui = $(this).data("ui"); 
		 		if (ui == undefined || ui == "" || ui == 0) { ui = true; }
		 		else{ ui = false; }

		 		var fitbound = $(this).data("fitbound"); 
		 		if (fitbound == undefined || fitbound == "" || fitbound == 0) { fitbound = false; }
		 		else{ fitbound = true; }

		 		// If address provided :  

		  		var address = $(this).data("addr"); 
		  		var dataLat = "";
			 	var dataLng = "";
			 	var html = $(this).html();
			 	var img = $(this).data("img");
 

			 	// Collection of marker html

			 	var html_marker_collection = new Array();

			 	// Bool for multiple markers

			 	var multiple_markers = 0;

			 	if ($(this).data("lat") != "" && $(this).data("lng")) {
			 		dataLat = $(this).data("lat");
			 		dataLng = $(this).data("lng");
			 	}

			 	//Generate position object with the primary position

			 	var primary_positionObject = {
			 		"primary":1,
			 		"address":address,
			 		"dataLat":dataLat,
			 		"dataLng":dataLng,
			 		"html":html,
			 		"img":img,
			 	};

			 	var li_count = $(this).find("li").length;

		 		// If the map have multiple markers, setup the collection of marker

		 		if (li_count)
		 		{
		 			if (!fitbound){		 				
			 			html_marker_collection.push(primary_positionObject);
		 			}

		 			$(this).find("li").each(function() {
		 				
		 				html_marker_collection.push({		 													
		 													"primary":0,
		 													"address":$(this).data("addr"),
		 													"dataLat":$(this).data("lat"),
		 													"dataLng":$(this).data("lng"),
	 														"html":$(this).html(),
		 													"img":$(this).data("img")
		 									   			});
		 			});
		 		}

		 		// Map declaration

		 		var map = new google.maps.Map($(".Map")[index], {
				    zoom: zoom,
				    center: {lat: 0, lng: 0},
				    scrollwheel: false, 
				    styles : colored,
				    disableDefaultUI: ui
				});

			 	// If is multiple markers

			 	if (html_marker_collection.length) 
			 	{
			 		multiple_markers = true;

			 		// Loop on all markers

				 	for (var i = 0; i < html_marker_collection.length; i++) 
				 	{
					  	// Initiate geocoding from map's data address, if provided
					 	if (html_marker_collection[i].address != "" && html_marker_collection[i].address != undefined) {
					 		locate_using_address(html_marker_collection[i],map ,$(this), marker, fitbound, multiple_markers); 
					 	} 
					 	// If not, use coordinates
					 	else if(html_marker_collection[i].dataLat != "" && html_marker_collection[i].dataLng != "" && html_marker_collection[i].dataLat != undefined && html_marker_collection[i].dataLng != undefined){
					 		locate_using_coords(html_marker_collection[i], map, marker, fitbound, multiple_markers); 
					 	} else{
					 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
					 		$(this).hide();
					 	}		 		
				 	}
			 	}
			 	else
			 	{			 		
				  	// Initiate geocoding from map's data address, if provided
				 	if (address != "" && address != undefined) {
				 		locate_using_address(primary_positionObject, map, $(this), marker, fitbound, multiple_markers); 
				 	} 
				 	// If not, use coordinates
				 	else if(dataLat != "" && dataLng != "" && dataLat != undefined && dataLng != undefined){
				 		locate_using_coords(primary_positionObject, map, marker, fitbound, multiple_markers); 
				 	} else{
				 		console.log($(this).attr('class')+" -> "+$(this).index()+" -> No data involved.");
				 		$(this).hide();
				 	}
			 	}

				// set_center_map(map, fitbound); 
			 	
			 	index++;

		 	});

	 	} // If js-map contains something
 		

 	} // initMap

	
	//  END MAP - Using provided address, or coords 
	//  -------------------------------------------------